import { Link } from "react-router-dom"
import logo from "../../assets/logo.png"
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="bg-[#DBEAFE] dark:bg-gray-900 mt-12">
      <div className="max-w-screen-2xl px-9 mx-auto py-5">
        <div className="py-2 lg:py-4 flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <Link to="/" className="flex items-center space-x-3 lg:mb-0">
            <img src={logo} className="h-8" alt="Aptechmedia Logo" />
            <span className="text-2xl font-semibold whitespace-nowrap text-gray-900 dark:text-white">
              Aptechmedia
            </span>
          </Link>
        </div>

        <div className="w-full flex flex-wrap justify-between mb-6 md:space-y-0">
          {/* <div className="grid grid-cols-2 gap-4 sm:gap-8 md:gap-16 lg:gap-48 px-4 lg:px-0 py-6 lg:py-8 md:grid-cols-3"> */}
          <div>
            <h2 className="mb-3 text-lg font-semibold text-gray-900 uppercase dark:text-white">
              Company
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {/* <li className="mb-2">
                <Link to="/games" className="hover:underline">
                  Games
                </Link>
              </li> */}
              <li className="mb-2">
                <Link to="/publishing" className="hover:underline">
                  Publishing
                </Link>
              </li>
              {/* <li className="mb-2">
                <Link to="/apps" className="hover:underline">
                  Apps
                </Link>
              </li> */}
              <li className="mb-2">
                <Link to="/apps" className="hover:underline">
                  Team
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/careers" className="hover:underline">
                  Careers
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/careers" className="hover:underline">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-3 text-lg font-semibold text-gray-900 uppercase dark:text-white">
              Help center
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-2">
                <a
                  href="https://www.facebook.com/people/AptechMedia/61558329702611/"
                  rel="noreferrer"
                  className="hover:underline"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="https://www.linkedin.com/company/aptechmedia/?originalSubdomain=pk"
                  rel="noreferrer"
                  target="_blank"
                  className="hover:underline"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-3 text-lg font-semibold text-gray-900 uppercase dark:text-white">
              Legal
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-2">
                <a href="/privacy-policy" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
              <li className="mb-2">
                <a href="/terms-conditions" className="hover:underline">
                  Terms &amp; Conditions{" "}
                </a>
              </li>
            </ul>
          </div>
          {/* </div> */}
        </div>
        <hr className=" border-slate-300 mb-5  mx-auto" />
        {/* Contact details */}
        <div className="">
          {/* <h1 className="text-3xl font-bold text-center">Let&apos;s connect</h1> */}
          <div className="w-full flex flex-wrap justify-between items-center mb-3 space-y-8 md:space-y-0">
            <div className="flex items-center space-x-4 max-w-xs">
              <div className="bg-white p-4 rounded-full">
                <FaMapMarkerAlt className="w-8 h-8 text-blue-500" />
              </div>
              <div>
                <h3 className="text-xl font-semibold">Our Office</h3>
                <p className="text-gray-700">
                  KPIT Park, PTCL Training Center, Canal Town Peshawar, Khyber
                  Pakhtunkhwa 25000
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4 max-w-xs">
              <div className="bg-white p-4 rounded-full">
                <FaPhoneAlt className="w-8 h-8 text-blue-500" />
              </div>
              <div>
                <h3 className="text-xl font-semibold">Phone</h3>
                <p className="text-gray-700">+92-3339371781</p>
              </div>
            </div>
            <div className="flex items-center space-x-4 max-w-xs">
              <div className="bg-white p-4 rounded-full">
                <FaEnvelope className="w-8 h-8 text-blue-500" />
              </div>
              <div>
                <h3 className="text-xl font-semibold">Email</h3>
                <p className="text-gray-700">Info@aptechmedia.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
