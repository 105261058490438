import React, { useState } from "react"
import Modal from "react-modal"
import { apiCall } from "../../lib/api"
import { toast } from "react-toastify"

const AddCategoryModal = ({ isOpen, onClose, isCategoryAdded }) => {
  const [iscategoryCreating, setIsCategoryCreating] = useState(false)
  const [formData, setFormData] = useState({
    title: "",
    image: null,
    techlogos: []
  })
  const [errors, setErrors] = useState({
    title: "",
    image: "",
    techlogos: ""
  })

  const handleChange = (e) => {
    const { name, value, files } = e.target
    const newValue = files ? files[0] : value

    setFormData({
      ...formData,
      [name]: newValue
    })

    // Remove the error message for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ""
    }))
  }

  const handleLogosChange = (e) => {
    const files = Array.from(e.target.files)
    setFormData({
      ...formData,
      techlogos: files
    })
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formData.title.trim()) {
      newErrors.title = "Title is required."
    }
    if (!formData.image) {
      newErrors.image = "Image is required."
    }
    if (formData.techlogos.length === 0) {
      newErrors.techlogos = "Add logos*"
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    setIsCategoryCreating(true)
    const data = new FormData()
    data.append("title", formData.title)
    data.append("image", formData.image)
    // Append images if they exist
    formData.techlogos.forEach((logo, index) => {
      data.append("tech_image[]", logo)
    })


    try {
      const response = await apiCall("post", "/api/addProjectCategory", data, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data"
      })
      if (response.status === 1) {
        setIsCategoryCreating(false)
        toast.success("Category added successfully", { autoClose: 3000 })
        onClose()
        isCategoryAdded(true)
      } else {
        setIsCategoryCreating(false)
        toast.error("Failed to add category.")
      }
    } catch (error) {
      setIsCategoryCreating(false)
      console.error("Error:", error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Job"
      className="modal"
      overlayClassName="overlay"
    >
      <form
        className="w-full max-w-md bg-white p-8 rounded shadow-lg"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">
          Portfolio Category
        </h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="title"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Enter category title"
            value={formData.title}
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? "border-red-500" : ""}`}
          />
          {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title}</p>}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="image"
          >
            Add Category Image
          </label>
          <input
            type="file"
            name="image"
            id="image"
            onChange={handleChange}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.image ? "border-red-500" : ""}`}
          />
          {errors.image && <p className="text-red-500 text-xs mt-1">{errors.image}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="techlogos" className="block text-gray-700 text-sm font-bold mb-2">
            Tech Logos
          </label>
          <input
            type="file"
            id="techlogos"
            name="techlogos"
            accept="image/*"
            onChange={handleLogosChange}
            multiple
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.techlogos ? "border-red-500" : ""}`}
          />
          {errors.techlogos && <p className="text-red-500 text-xs mt-1">{errors.techlogos}</p>}
        </div>
        <div className="showImages flex gap-2">
          {
            formData.techlogos.length > 0 && formData?.techlogos?.map((logoSource, index) => {
              return (
                <div key={index} >
                  <img src={URL.createObjectURL(logoSource)} alt="logo" className="w-10 h-10" />
                </div>
              )
            })
          }
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded-lg disabled:bg-blue-400"
            disabled={iscategoryCreating}
          >
            {iscategoryCreating ? "Creating..." : "Create"}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default AddCategoryModal
