import { useState } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import { apiCall } from "../../lib/api"

const AddProject = ({ isOpen, onClose, categories }) => {
  const [isAddingProject, setIsAddingProject] = useState(false)
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    images: [],
    video: null,
    project_url: "",
    category_id: ""
  })
  const [validationErrors, setValidationErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    setFormData({
      ...formData,
      images: files
    })
  }

  const handleVideoChange = (e) => {
    const file = e.target.files[0]
    setFormData({
      ...formData,
      video: file
    })
  }

  const validateForm = () => {
    const errors = {}
    if (!formData.title.trim()) errors.title = "Project title is required"
    if (formData.images.length === 0) errors.images = "At least one image is required"
    if (!formData.category_id) errors.category_id = "Please select a category"
    // if (!formData.project_url.trim()) errors.project_url = "Project link is required"
    if (!formData.description.trim()) errors.description = "Description is required"
    setValidationErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Validate form data
    if (!validateForm()) return

    setIsAddingProject(true)
    const data = new FormData()
    data.append("title", formData.title)
    data.append("category_id", formData.category_id)
    data.append("description", formData.description)
    data.append("project_url", formData.project_url)
    if (formData.video) data.append("video", formData.video)

    // Append images if they exist
    formData.images.forEach((image, index) => {
      data.append(`images[${index}]`, image)
    })

    try {
      const response = await apiCall("post", "/api/addProject", data, {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data"
      })

      if (response.status === 1) {
        toast.success("Project added successfully", { autoClose: 3000 })
        setIsAddingProject(false)
        onClose()
      } else {
        toast.error("Failed to add project")
        setIsAddingProject(false)
      }
    } catch (error) {
      setIsAddingProject(false)
      toast.error("An error occurred while adding the project")
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Project"
      className="modal"
      overlayClassName="overlay"
    >
      <h2 className="text-xl font-semibold mb-4">Add Project</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="title" className="block text-sm font-medium">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Enter project title"
            value={formData.title}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {validationErrors.title && <p className="text-red-500 text-sm">{validationErrors.title}</p>}
        </div>

        <div>
          <label htmlFor="images" className="block text-sm font-medium">Images</label>
          <input
            type="file"
            id="images"
            name="images"
            accept="image/*"
            onChange={handleImageChange}
            multiple
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {validationErrors.images && <p className="text-red-500 text-sm">{validationErrors.images}</p>}
        </div>

        <div>
          <label htmlFor="video" className="block text-sm font-medium">Video</label>
          <input
            type="file"
            id="video"
            name="video"
            accept="video/*"
            onChange={handleVideoChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label htmlFor="category_id" className="block text-sm font-medium">Category</label>
          <select
            id="category_id"
            name="category_id"
            value={formData.category_id}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">Choose a Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
            ))}
          </select>
          {validationErrors.category_id && <p className="text-red-500 text-sm">{validationErrors.category_id}</p>}
        </div>

        <div>
          <label htmlFor="project_url" className="block text-sm font-medium">Project Link</label>
          <input
            type="text"
            id="project_url"
            name="project_url"
            placeholder="Add link"
            value={formData.project_url}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {validationErrors.project_url && <p className="text-red-500 text-sm">{validationErrors.project_url}</p>}
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium">Description</label>
          <textarea
            id="description"
            name="description"
            placeholder="Enter project description"
            value={formData.description}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {validationErrors.description && <p className="text-red-500 text-sm">{validationErrors.description}</p>}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-300 px-4 py-2 rounded-md text-sm"
            disabled={isAddingProject}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 px-4 py-2 rounded-md text-sm text-white disabled:bg-blue-400"
            disabled={isAddingProject}
          >
            {isAddingProject ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default AddProject
