import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Aos from "aos"
import "aos/dist/aos.css"
import { lazy, Suspense, useEffect } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ScrollToTop from "./components/ScrollToTop"
import Loader from "./components/Loader"

const Home = lazy(() => import("./pages/Home"))
const Partner = lazy(() => import("./pages/partnersPortfolio/PortfolioOne"))
const Game = lazy(() => import("./pages/Game"))
const Contact = lazy(() => import("./pages/Contact"))
const Publishing = lazy(() => import("./pages/Publishing"))
const Apps = lazy(() => import("./pages/Apps"))
const Careers = lazy(() => import("./pages/Careers"))
const Privacy = lazy(() => import("./pages/Privacy"))
const Terms = lazy(() => import("./pages/Terms"))
const JobApply = lazy(() => import("./pages/JobApply"))
const JobForm = lazy(() => import("./pages/JobForm"))
const AdminLogin = lazy(() => import("./pages/AdminLogin"))
const Dashboard = lazy(() => import("./pages/Dashboard"))
const Applicants = lazy(() => import("./pages/Applicants"))
const Portfolio = lazy(() => import("./pages/Portfolio"))
const Team = lazy(() => import("./pages/Team"))
const ProtectedRoute = lazy(() => import("./components/auth/ProtectedRoute"))
const Projects = lazy(() => import("./pages/Projects"))
const AdminProjects = lazy(() => import("./components/admin/Projects"))
const TeamAdmin = lazy(() => import("./components/admin/team/GetTeam"))

import Navbar from "../src/components/Layout/Navbar"
import Footer from "../src/components/Layout/Footer"
import PortfolioUpdate from "./components/admin/PortfolioUpdate"

const Layout = () => (
  <>
    {/* <Suspense fallback={<Loader />}> */}
    <Navbar />
    {/* </Suspense> */}
    <Outlet />
    {/* <Suspense fallback={<Loader />}> */}
    <Footer />
    {/* </Suspense> */}
  </>
)

function App() {
  useEffect(() => {
    Aos.init()
  }, [])

  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            />
            {/* <Route path="games" element={<Suspense fallback={<Loader />}><Game /></Suspense>} /> */}
            <Route
              path="portfolio"
              element={
                <Suspense fallback={<Loader />}>
                  <Portfolio />
                </Suspense>
              }
            />
            <Route
              path="contact"
              element={
                <Suspense fallback={<Loader />}>
                  <Contact />
                </Suspense>
              }
            />
            <Route
              path="publishing"
              element={
                <Suspense fallback={<Loader />}>
                  <Publishing />
                </Suspense>
              }
            />
            <Route
              path="apps"
              element={
                <Suspense fallback={<Loader />}>
                  <Apps />
                </Suspense>
              }
            />
            <Route
              path="team"
              element={
                <Suspense fallback={<Loader />}>
                  <Team />
                </Suspense>
              }
            />
            <Route
              path="careers"
              element={
                <Suspense fallback={<Loader />}>
                  <Careers />
                </Suspense>
              }
            />
            <Route
              path="privacy-policy"
              element={
                <Suspense fallback={<Loader />}>
                  <Privacy />
                </Suspense>
              }
            />
            <Route
              path="terms-conditions"
              element={
                <Suspense fallback={<Loader />}>
                  <Terms />
                </Suspense>
              }
            />
            <Route
              path="careers/apply"
              element={
                <Suspense fallback={<Loader />}>
                  <JobApply />
                </Suspense>
              }
            />
            <Route
              path="careers/apply/:jobId"
              element={
                <Suspense fallback={<Loader />}>
                  <JobForm />
                </Suspense>
              }
            />
            <Route
              path="/portfolio/:fieldTitle"
              element={
                <Suspense fallback={<Loader />}>
                  <Projects />
                </Suspense>
              }
            />
            <Route
              path="portfolioOne/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <Partner />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/admin/login"
            element={
              <Suspense fallback={<Loader />}>
                <AdminLogin />
              </Suspense>
            }
          />
          <Route
            path="/admin"
            element={
              <Suspense fallback={<Loader />}>
                <ProtectedRoute />
              </Suspense>
            }
          >
            <Route
              path="dashboard"
              element={
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="applicants"
              element={
                <Suspense fallback={<Loader />}>
                  <Applicants />
                </Suspense>
              }
            />
            <Route
              path="/admin/portfolio/projects/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <AdminProjects />
                </Suspense>
              }
            />
            <Route
              path="/admin/portfolio"
              element={
                <Suspense fallback={<Loader />}>
                  <PortfolioUpdate />
                </Suspense>
              }
            />
            <Route
              path="/admin/team"
              element={
                <Suspense fallback={<Loader />}>
                  <TeamAdmin />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </Router>
      <ToastContainer position="top-center" autoClose={2000} />
    </div>
  )
}

export default App
