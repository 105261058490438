import { useEffect, useState } from "react"
import Modal from "react-modal"
import Loader from "../Loader"
import { apiCall } from "../../lib/api"
import { toast } from "react-toastify"
import { MdDeleteForever } from "react-icons/md"

const EditCategoryModal = ({ isOpen, singleCategory, onClose, isCategoryUpdated, loadingSingleCategory }) => {

  const [formData, setFormData] = useState({
    title: "",
    image: null,
    techlogos: []
  })
  const [errors, setErrors] = useState({ title: "", image: "" })
  const [isEditing, setIsEditing] = useState(false)
  const [existingLogos, setExistingLogos] = useState([])
  const [deletingSingleImage, setDeletingSingleImage] = useState(false)


  const handleChange = (e) => {
    const { name, value, files } = e.target
    setFormData({
      ...formData,
      [name]: files ? files[0] : value
    })
    setErrors({
      ...errors,
      [name]: ""
    })
  }

  const handleLogosChange = (e) => {
    const files = Array.from(e.target.files)
    setFormData({
      ...formData,
      techlogos: files
    })
  }


  const validateForm = () => {
    const newErrors = {}
    if (!formData.title.trim()) {
      newErrors.title = "Title is required."
    }
    // if (!formData.image) {
    //   newErrors.image = "Image is required."
    // }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validateForm()) return

    const data = new FormData()
    data.append("title", formData.title)
    data.append("image", formData.image)
    formData?.techlogos?.forEach((logo, index) => {
      data.append(`tech_image[${index}][file]`, logo)
    })
    setIsEditing(true)
    try {
      const response = await apiCall("post", `/api/updateProjectCategory/${singleCategory.id}`, data,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        }
      )
      if (response.status === 1) {
        toast.success(
          "Category updated successfully",
          {
            autoClose: 3000
          })
        onClose()
        isCategoryUpdated(true)
        setIsEditing(false)
      } else {
        toast.error("Failed to update category.")
        setIsEditing(false)
      }
    } catch (error) {
      console.error("Error:", error)
      setIsEditing(false)
    }
  }

  // Remove logo
  const removeSingleExtraImage = async (id) => {
    setDeletingSingleImage(true)
    try {
      const response = await apiCall("delete", `/api/deleteTechImage/${id}`, {}, {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      })
      if (response.status === 1) {
        toast.success("Image deleted successfully", {
          autoClose: 2000
        })
        setDeletingSingleImage(false)
      }
    } catch (error) {
      toast.error(error.message)
      setDeletingSingleImage(false)
    }
  }

  const handleRemoveExistingImage = async (image) => {
    if (existingLogos?.length > 1) {
      await removeSingleExtraImage(image.id)
      setExistingLogos(existingLogos.filter((img) => img !== image))
    } else {
      toast.info("One image is necessary for project", {
        autoClose: 2000
      })
    }
  }

  useEffect(() => {
    if (!loadingSingleCategory) {
      setFormData({
        title: singleCategory.title,
        image: singleCategory.images
      })
      setExistingLogos(singleCategory?.tech_img)
      setErrors({ title: "", image: "" })
    }
  }, [loadingSingleCategory])


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Job"
      className="modal"
      overlayClassName="overlay"
    >
      {
        !loadingSingleCategory ?
          <>
            <form
              className="w-full max-w-md bg-white p-8 rounded shadow-lg"
              onSubmit={handleSubmit}
            >
              <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">
                Edit Category
              </h2>
              <img src={singleCategory?.image} className="h-32 rounded-md mb-3" alt="Category Image" />
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter category title"
                  value={formData.title}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errors.title && <p className="text-red-500 text-xs italic">{errors.title}</p>}
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="image"
                >
                  Category Image
                </label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="grid grid-cols-4 gap-4 my-5">
                {
                  existingLogos.length > 0 && existingLogos.map((image, index) => (
                    <div className="relative mt-3" key={index}>
                      <img src={image.image} alt="Project" className="rounded-md w-20" />
                      <MdDeleteForever size={22} color={"#F41A4A"} className="absolute top-1 bg-slate-300 rounded-sm cursor-pointer" onClick={() => handleRemoveExistingImage(image)} />
                    </div>
                  ))
                }
              </div>
              <div className="mb-4">
                <label htmlFor="techlogos" className="block text-gray-700 text-sm font-bold mb-2">
                  Tech Logos
                </label>
                <input
                  type="file"
                  id="techlogos"
                  name="techlogos"
                  accept="image/*"
                  onChange={handleLogosChange}
                  multiple
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.techlogos ? "border-red-500" : ""}`}
                />
              </div>
              {/* {errors.image && <p className="text-red-500 text-xs italic">{errors.image}</p>} */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg disabled:bg-blue-500"
                  disabled={isEditing}
                >
                  {isEditing ? "Editing..." : "Edit Category"}
                </button>
              </div>
            </form>
          </> : <Loader />
      }
      {(deletingSingleImage) && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70">
          <Loader />
        </div>
      )}
    </Modal>
  )
}

export default EditCategoryModal
