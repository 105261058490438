import { useEffect, useState } from "react"
import Sidebar from "./Sidebar"
import { useNavigate } from "react-router-dom"
import { FaArrowLeft, FaArrowRight, FaBars, FaEdit, FaEye } from "react-icons/fa"
import { apiCall } from "../../lib/api"
import AddCategoryModal from "./AddCategoryModal"
import EditCategoryModal from "./EditCategoryModal"
import { FaPlus } from "react-icons/fa"
import AddProject from "./AddProject"

const PortfolioUpdate = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(true)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [categoryAdded, setCategoryAdded] = useState(false)
  // Edit
  const [openEditModal, setOpenEditModal] = useState(false)
  const [singleCategory, setSingleCategory] = useState(null)
  const [categoryUpdated, setCategoryUpdated] = useState(false)

  // Project
  const [openAddProjectModal, setOpenProjectModal] = useState(false)
  const [loadingSingleCategory, setLoadingSingleCategory] = useState(false)


  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated")
    navigate("/admin/login")
  }

  const fetchCategories = async (page) => {
    setLoading(true)
    try {
      const response = await apiCall(
        "get",
        `/api/getProjectCategory?page=${page}`,
        {},
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      )
      setCategories(response.data.data)
      setCurrentPage(response.data.current_page)
      setTotalPages(response.data.last_page)
    } catch (error) {
      console.error("Error fetching applicants:", error)
    }
    setLoading(false)
  }

  const getSingleCategory = async (id) => {
    setLoadingSingleCategory(true)
    try {
      const response = await apiCall(
        "get",
        `/api/getSingleCategory/${id}`,
        {},
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      )
      if (response.status === 1) {
        setSingleCategory(response?.data)
        setLoadingSingleCategory(false)
      }
    } catch (error) {
      console.log("Something Went Wrong!")
      setLoadingSingleCategory(false)
    }
  }

  const isCategoryAdded = (val) => {
    setCategoryAdded(val)
  }


  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const closeAddModal = () => {
    setOpenAddModal(false)
  }


  // singleCategoryUpdates -- edit
  const singleCategoryUpdates = (category) => {
    setOpenEditModal(true)
    getSingleCategory(category.id)
  }

  const closeEditModal = () => {
    setOpenEditModal(false)
  }

  const isCategoryUpdated = (val) => {
    setCategoryUpdated(val)
  }

  // Add Project
  const closeAddProjectModal = () => {
    setOpenProjectModal(false)
  }


  useEffect(() => {
    fetchCategories(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (categoryAdded || categoryUpdated) {
      fetchCategories(currentPage)
    }
  }, [categoryAdded, categoryUpdated])

  // Pagination
  const renderPagination = () => {
    const pageNumbers = []
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (currentPage <= 3) {
      endPage = Math.min(5, totalPages)
    }
    if (currentPage >= totalPages - 2) {
      startPage = Math.max(1, totalPages - 4)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${i === currentPage ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-700"}`}
        >
          {i}
        </button>
      )
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
          disabled={currentPage === 1}
        >
          <FaArrowLeft />
        </button>
        {pageNumbers}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700"
          disabled={currentPage === totalPages}
        >
          <FaArrowRight />
        </button>
      </div>
    )
  }
  return (
    <div className="min-h-screen flex bg-gray-100">
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        onLogout={handleLogout}
      />
      <div className="flex-1 flex flex-col">
        <header className="flex items-center justify-between p-4 bg-white shadow">
          <button onClick={() => setIsSidebarOpen(true)} className="lg:hidden">
            <FaBars />
          </button>
          <h1 className="text-2xl font-bold">Portfolio</h1>
        </header>
        <main>
          <div className="flex justify-end">
            <button className="bg-blue-600 px-3 py-1 rounded-md flex items-center gap-2 text-white my-2 me-3" onClick={() => setOpenAddModal(true)}>
              <FaPlus />
              Category
            </button>
            <button className="bg-blue-600 px-3 py-1 rounded-md flex items-center gap-2 text-white my-2 me-3" onClick={() => setOpenProjectModal(true)}>
              <FaPlus color="white" />
              Project
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center items-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : categories.length === 0 ? (
            <div className="text-center text-gray-500">No applicants found</div>
          ) : (
            <div>
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="bg-slate-400 text-white">
                    <th scope="col" className="px-4 py-2 border-b text-start">Category Name</th>
                    <th scope="col" className="px-4 py-2 border-b text-start">Edit</th>
                    <th scope="col" className="px-4 py-2 border-b">View Projects</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    categories.map((category, index) => {
                      return (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <td className="px-4 py-2 border-b">{category.title}</td>
                          <td className="px-4 py-2 border-b cursor-pointer"><FaEdit className="ms-2"
                            onClick={() => singleCategoryUpdates(category)}
                          /></td>
                          <td className="px-4 py-2 border-b">
                            <FaEye onClick={() => navigate(`/admin/portfolio/projects/${category.id}`)} className="cursor-pointer  ms-8" size={20} color="blue" />
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>

              </table>
            </div>
          )}
          {renderPagination()}

          {/* Add Modal */}
          {
            openAddModal && <AddCategoryModal
              isOpen={openAddModal}
              onClose={closeAddModal}
              isCategoryAdded={isCategoryAdded}
            />
          }
          {/* Edit Modal */}
          {
            openEditModal && <EditCategoryModal
              isOpen={openEditModal}
              onClose={closeEditModal}
              loadingSingleCategory={loadingSingleCategory}
              singleCategory={singleCategory}
              isCategoryUpdated={isCategoryUpdated}
            />
          }

          {/* Project Modal */}
          {
            openAddProjectModal && <AddProject
              isOpen={openAddProjectModal}
              categories={categories}
              onClose={closeAddProjectModal}
            />
          }

        </main>
      </div>
    </div>
  )
}

export default PortfolioUpdate